import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "styles",
      "style": {
        "position": "relative"
      }
    }}>{`Styles`}</h1>
    <p>{`Bold uses CSS-in-JS to style its components, so all the CSS is bundled together with the JS file and attached to the DOM at execution time. We use `}<a parentName="p" {...{
        "href": "https://emotion.sh"
      }}>{`emotion`}</a>{`, a CSS-in-JS library, to acomplish that.`}</p>
    <h2 {...{
      "id": "styling-with-usestyles-hook",
      "style": {
        "position": "relative"
      }
    }}>{`Styling with `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`useStyles`}</code>{` hook`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useStyles`}</code>{` function is a `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-intro.html"
      }}>{`React hook`}</a>{` that can be used to generate CSS stylesheets and inject to custom components. It returns an object containing:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`classes`}</code>{`: A map containing the generated classes, to be used as `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`className`}</code>{` of your components`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`css`}</code>{`: The emotion's `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`css`}</code>{` function which is used to generate the classes`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`theme`}</code>{`: The current bold theme`}</li>
    </ul>
    <Demo src='pages/usage/styles/UseStyles' mdxType="Demo" />
    <h3 {...{
      "id": "using-css-function",
      "style": {
        "position": "relative"
      }
    }}>{`Using `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`css`}</code>{` function`}</h3>
    <p>{`You can also use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`css`}</code>{` function to create stylesheets:`}</p>
    <Demo src='pages/usage/styles/Css' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      